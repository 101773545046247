import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"

const Tagebuch = ({ data, location }) => {
  // Array of all news articles
  const allNews = data.allMdx.nodes
  // State for the list
  const [list, setList] = useState([...allNews.slice(0, 50)])
  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)
  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allNews.length > 50)
  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }
  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allNews.length
      const nextResults = isMore
        ? allNews.slice(currentLength, currentLength + 50)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line
  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allNews.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  // const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes
  const headerImage = posts[0].frontmatter.featuredImage?.childImageSharp?.fluid 

  // if (posts.length === 0) {
  //   return (
  //     <Layout location={location} title={siteTitle}>
  //       <SEO title="All posts" />
  //       <Bio />
  //       <p>
  //         No blog posts found. Add markdown posts to "content/blog" (or the
  //         directory you specified for the "gatsby-source-filesystem" plugin in
  //         gatsby-config.js).
  //       </p>
  //     </Layout>
  //   )
  // }

  return (
    <Layout location={location} title="Tagebuch" slogan="Berichte des Altersheim Bannau über Ausflüge, Feieren und Veranstaltungen" headerImage={headerImage}>
      <ol style={{ listStyle: `none`, margin: '0' }} className="grid grid-2">
        {list.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <Link to={"/tagebuch"+post.fields.slug} itemProp="url" style={{textDecoration: 'none'}}>
                    <Img fluid={post.frontmatter.featuredImage?.childImageSharp?.fluid} style={{width: '100%', maxHeight: '340px'}}/>
                    <h2 style={{marginTop: '0.75em'}}>
                        <span itemProp="headline">{title}</span>
                    </h2>
                  </Link>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          )
        })}
      </ol>
      {hasMore ? (
        <button onClick={handleLoadMore} className="loadMorePosts">Mehr Beiträge laden</button>
      ) : (
        <>
        <hr style={{marginTop: '2rem'}}/>
        <p style={{marginTop: '2rem'}}>Sie haben alle Beiträge gesehen - die Webseite besteht seit 2014.</p>
        </>
      )}
    </Layout>
  )
}

export default Tagebuch

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "LL", locale: "de")
          title
          description
          featuredImage {
            childImageSharp {
                fluid(quality: 90, maxWidth: 680) {
                  ...GatsbyImageSharpFluid_withWebp
                }
            }
          }
        }
      }
    }
  }
`
